<template>
  <div class="wxCode-container" v-loading="loading"
       element-loading-text="登录中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(255, 255, 255, 0.5)">
  </div>
</template>

<script>
import { getCurrentUser } from "@/api/login";
export default {
  name: "wxcode",
  data() {
    return {
      textStatus: null,
      loading: true
    };
  },
  methods: {
    getCodeOrToken() {
      let openId = this.$route.query.openId;
      let token = this.$route.query.token;
      if (openId) {
        let data = {
          openId: openId
        }
        this.$router.push({
          name: "accountBinding",
          params:{
            data: data
          }
        })
      } else if (token) {
        this.handleStoreToken(token);
        getCurrentUser()
          .then((res) => {
            if (res.code === 200) {
              let data = res.data;
              this.handleStore(data);
              let userLogin = JSON.stringify({ phone: data.phone });
              localStorage.setItem("userLogin", userLogin);
              this.$router.push({ path: "/" });
            } else {
              this.loginFailed(this.$t("L_LOGIN.LOGIN_0007"));
            }
          })
          .catch(() => {
            this.loginFailed(this.$t("L_LOGIN.LOGIN_0007"));
          });
      }
    },
    handleMessaage(text1, text2) {
      this.$message({
        message: this.language === "en" ? text1 : text2,
        type: "error",
        offset: 60,
      });
    },
    loginFailed(text) {
      this.$router.push({ path: "/login" });
      this.handleMessaage(text, text);
    },
    handleStoreToken(token) {
      this.$store.commit("setToken", token);
    },
    handleStore(info) {
      this.$store.commit("setUserInfo", info);
    },
  },
  mounted() {
    this.getCodeOrToken()
  },
};
</script>

<style lang="scss" scoped>
.wxCode-container {
  width: 100%;
  height: 100%;
  background: url("../assets/image/error/errorbg.jpg") 0 0 no-repeat;
  background-size: 100% 100%;
}
:deep(.el-loading-mask) {
  .el-loading-spinner .el-loading-text {
    font-size: 35px;
    letter-spacing: 1px;
    color: #fff;
  }
  .el-loading-spinner i {
    color: #fff;
    font-size: 50px;
  }
}

@media screen and (max-width: 414px){
  .error-container {
    background: url("../assets/image/error/errorbg.jpg") 0 0 no-repeat;
    background-size: auto 100%;
    .error-info-box {
      width: 300px;
      h3 {
        font-size: 24px;
      }
    }
  }
}
@media screen and (min-width: 415px) and (max-width: 599px){
  .error-container {
    background: url("../assets/image/error/errorbg.jpg") 0 0 no-repeat;
    background-size: auto 100%;
    .error-info-box {
      width: 300px;
      h3 {
        font-size: 26px;
      }
    }
  }
}
@media screen and (min-width: 600px) and (max-width: 799px){
  .error-container {
    background: url("../assets/image/error/errorbg.jpg") 0 0 no-repeat;
    background-size: auto 100%;
    .error-info-box {
      width: 400px;
      h3 {
        font-size: 28px;
      }
    }
  }
}
@media screen and (min-width: 800px) and (max-width: 1024px){
  .error-container {
    background: url("../assets/image/error/errorbg.jpg") 0 0 no-repeat;
    background-size: auto 100%;
    .error-info-box {
      width: 500px;
      h3 {
        font-size: 33px;
      }
    }
  }
}
</style>